<!--
 * @Descripttion: (代发货订单)
-->
<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="pages" v-loading="loading">

    <table-height-auto>
      <template slot="before">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item>
            <el-select v-model="searchData.searchType"
                       placeholder="请选择关键字类型"
                       style="width: 120px">
              <el-option
                v-for="item in searchKeyType"
                :disabled="item.disabled"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-input class=""
                      placeholder="搜索关键字"
                      prefix-icon="el-icon-search"
                      v-model="searchData.searchValue"
                      @keyup.enter.native="search"
                      style="width: 260px">
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-select v-model="searchData.timeType"
                       placeholder="时间类型"
                       style="width: 120px">
              <el-option
                v-for="item in searchTimeType"
                :disabled="item.disabled"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="查询时间">
            <el-date-picker
              v-model="searchData.selectTime"
              type="daterange"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="search">查询</el-button>
          </el-form-item>

          <el-form-item>
            <el-button @click="downloadExcel" icon="el-icon-download">导出</el-button>
          </el-form-item>
        </el-form>
        <div class="order-page-title">
          <div class="flex-4">
            <div class="flex">
              <div class="flex-3">订单商品</div>
              <div class="flex-1">商品类型</div>
              <div class="flex-1">单价/数量</div>
              <div class="flex-1">退款/售后</div>
            </div>
          </div>
          <div class="flex-1 padding-left-10">订单金额</div>
          <div class="flex-2 padding-left-10">收货人信息</div>
          <div class="flex-1 padding-right-30" style="text-align: right">订单状态</div>
        </div>
      </template>

      <template v-slot:table="row">
        <div class="order-page-list" v-if="tableData.length > 0">
          <div class="order-item" v-for="order in tableData">
            <div class="order-item-top">
              <!-- <span>订单号：{{order.id}}</span>  -->
              <span v-if="[0].includes(order.orderType)">主订单号：{{order.id}}</span>
              <span v-if="[3].includes(order.orderType)">主订单号：{{order.parentOrderId}}</span>
              <span v-if="[3].includes(order.orderType)" class="margin-left-30">子订单号：{{order.id}}</span>
              <span class="margin-left-30">下单时间：{{order.createTime_text}}</span>
              <span class="margin-left-30" v-if="order.payTime_text">支付时间：{{order.payTime_text}}</span>
            </div>
            <div class="order-item-content">
              <div class="flex-4 border-right">
                <div class="order-item-goods flex" v-for="goods in order.orderGoodsList">
                  <div class="flex flex-3">
                    <el-image class="radius-6" style="width: 62px; height: 62px" :src="goods.cover"></el-image>
                    <div class="flex-1 padding-left-10 padding-right-30 flex flex-column">
                      <div class="goods-name flex-1">{{goods.goodsName}}</div>
                      <div class="gray">{{goods.skuName}}</div>
                    </div>
                  </div>
                  <div class="flex flex-1">
                    <!-- == 1 ? '普通商品' : '批发商品' -->
                    <div>{{ businessTypeList[goods.businessType -1]  }}</div>
                  </div>
                  <div class="flex-1 flex flex-column">
                    <div class="goods-price flex-1">¥ {{goods.price}}</div>
                    <div class="gray"> x{{goods.goodsNum}}</div>
                  </div>
                  <div class="goods-refund flex-1">
                    <div v-if="isNormalLongId(goods.orderRefundId)">
                      <el-link style="color: red" :underline="false" :href="`/orderRefundDetail?refundId=${goods.orderRefundId}`">{{goodsRefundStatus[goods.refundStatus]}}</el-link>
                    </div>
                    <div v-else>{{goodsRefundStatus[goods.refundStatus]}}</div>
                  </div>
                </div>
              </div>
              <div class="flex-1 padding-left-10 border-right">
                <div class="padding-10">
                  <div class="f18 b">￥{{order.realAmount}}</div>
                  <div class="f14 gray margin-top-10">运费：￥{{order.expressFee}}</div>
                </div>
              </div>
              <div class="flex-2 padding-left-10 border-right">
                <div class="padding-10">
                  <div class="flex">
                    <div class="">买家</div>
                    <div class="flex-1 margin-left-30 f14">
                      <div class=""><span>{{order.receiveName}}</span> <span class="margin-left-30 f16">{{order.receivePhone}}</span> </div>
                      <div class="margin-top-10">{{order.receiveAddressInfo}}</div>
                    </div>
                  </div>
                  <div class="flex margin-top-30">
                    <div class="">留言</div>
                    <div class="flex-1 margin-left-30 f14 red">{{order.remark}}</div>
                  </div>
                </div>
              </div>
              <div class="flex-1 padding-right-30" style="text-align: right">
                <div class="padding-10">
                  <div class="f18 b">{{orderStatus[order.status]}}</div>
                  <div class="margin-top-10">
                    <el-button size="medium" type="text"  @click="singleSend(order)">发货</el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div style="text-align: center; margin-top: 200px;" class="no-data">暂无数据</div>
        </div>
      </template>

      <template slot="after">
        <div class="paging-row">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalListNum">
          </el-pagination>
        </div>
      </template>
    </table-height-auto>
    <!-- 相同地址订单合并发货 -->
    <el-drawer
      class="drawer-scroll-y"
      ref="drawerMergeSendOrder"
      @closed="closed"
      :close-on-press-escape="false"
      :wrapperClosable="false"
      :destroy-on-close="true"
      :visible.sync="showMergeSendOrder"
      title="相同地址订单合并发货"
      direction="rtl"
      size="60%">
      <div class="drawer-scroll-y-box padding-top-10  margin-left-30">

        <div class="order-page-wrap margin-right-30">
          <div class="order-page-title">
            <div class="flex-4">
              <div class="flex">
                <div class="flex-3">订单商品</div>
                <div class="flex-1">单价/数量</div>
                <div class="flex-1">退款/售后</div>
              </div>
            </div>
            <div class="flex-1 padding-left-10">订单金额</div>
            <div class="flex-2 padding-left-10">买家信息</div>
            <div class="flex-1 padding-right-30" style="text-align: right">订单状态</div>
          </div>
          <div class="order-page-list">
            <div class="order-item" v-for="order in gridData">
              <div class="order-item-top">
                <span>订单号：{{order.id}}</span> <span class="margin-left-30">下单时间：{{order.createTime_text}}</span> <span class="margin-left-30" v-if="order.payTime_text">支付时间：{{order.payTime_text}}</span>
              </div>
              <div class="order-item-content">
                <div class="flex-4 border-right">
                  <div class="order-item-goods flex" v-for="goods in order.orderGoodsList">
                    <div class="flex flex-3">
                      <el-image class="radius-6" style="width: 62px; height: 62px" :src="goods.cover"></el-image>
                      <div class="flex-1 padding-left-10 padding-right-30 flex flex-column">
                        <div class="goods-name flex-1">{{goods.goodsName}}</div>
                        <div class="gray">{{goods.skuName}}</div>
                      </div>
                    </div>
                    <div class="flex-1 flex flex-column">
                      <div class="goods-price flex-1">¥ {{goods.price}}</div>
                      <div class="gray"> x{{goods.goodsNum}}</div>
                    </div>
                    <div class="goods-refund flex-1">
                      <div :class="goods.refundStatus === 1 ? 'flex-1 red':'flex-1'" v-if="goods.refundStatus">{{goodsRefundStatus[goods.refundStatus]}}</div>
                    </div>
                  </div>
                </div>
                <div class="flex-1 padding-left-10 border-right">
                  <div class="padding-10">
                    <div class="f18 b">￥{{order.realAmount}}</div>
                    <div class="f14 gray margin-top-10">运费：￥{{order.expressFee}}</div>
                  </div>
                </div>
                <div class="flex-2 padding-left-10 border-right">
                  <div class="padding-10">
                    <div class="flex">
                      <div class="">买家</div>
                      <div class="flex-1 margin-left-30 f14">
                        <div class=""><span>{{order.receiveName}}</span> <span class="margin-left-30 f16">{{order.receivePhone}}</span> </div>
                        <div class="margin-top-10">{{order.receiveAddressInfo}}</div>
                      </div>
                    </div>
                    <div class="flex margin-top-30">
                      <div class="">留言</div>
                      <div class="flex-1 margin-left-30 f14 red">{{order.remark}}</div>
                    </div>
                  </div>
                </div>
                <div class="flex-1 padding-right-30" style="text-align: right">
                  <div class="padding-10">
                    <div class="f18 b">{{orderStatus[order.status]}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-item-form">
          <div class="text">配送方式：</div>
          <div class="text">
            <el-radio-group v-model="expressWay" @change="onExpressWay">
              <el-radio :label="1">快递</el-radio>
              <el-radio :label="2">县域配送</el-radio>
              <el-radio :label="3">到店自提</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="express-item-option" v-if="expressWay === 1">
          <div class="inline-block">
            <el-select v-model="form.expressCode" placeholder="请选择快递公司"  size="small">
              <el-option
                v-for="expressItem in expressCompanyArr"
                :key="expressItem.code"
                :label="expressItem.name"
                :value="expressItem.code">
              </el-option>
            </el-select>
          </div>
          <div class="margin-left inline-block">
            <el-input v-model="form.expressSn" placeholder="请填写物流单号" size="small"></el-input>
          </div>
        </div>
        <div class="margin-top">
          <el-button @click="cancelForm" size="small">取 消</el-button>
          <el-button type="primary" @click="submitBatchSendOrder" size="small">确 定</el-button>
        </div>
      </div>
    </el-drawer>

    <!-- 发货 -->
    <el-drawer
      class="drawer-scroll-y"
      ref="drawerSendOrder"
      @closed="closed"
      :close-on-press-escape="false"
      :wrapperClosable="false"
      :destroy-on-close="true"
      :visible.sync="visibleSingleSendDrawer"
      title="发货"
      direction="rtl"
      size="60%">
      <div class="drawer-scroll-y-box padding-top-10  margin-left-30">
        <div class="flex-item-form" v-if="[0].includes(selectedOrderType)">
          <div class="text">主订单号：</div>
          <div class="text">{{selectedOrderId}}</div>
        </div>
        <div class="flex-item-form" v-if="[3].includes(selectedOrderType)">
          <div class="text">主订单号：</div>
          <div class="text">{{selectedParentOrderId}}</div>
        </div>
        <div class="flex-item-form" v-if="[3].includes(selectedOrderType)">
          <div class="text">子订单号：</div>
          <div class="text">{{selectedOrderId}}</div>
        </div>
        <div class="flex-item-form">
          <div class="text">收件信息：</div>
          <div class="text">{{orderItemObj.receiveAddressInfo}}   -   {{orderItemObj.receiveName}}   -   {{orderItemObj.receivePhone}}</div>
        </div>
        <!-- <el-alert type="info" center show-icon v-if="gridData.length > 1">
          <div slot="title">检测到该地址有配送方式为{{userAssignDeliveryMethod}}的相同订单共{{gridData.length}}笔,是否需要合并发货? <el-button type="text" @click="batchSendView">去合并发货</el-button> </div>
        </el-alert> -->
        <div class="flex-item-form">
          <div class="text">买家留言：</div>
          <div class="text">{{orderItemObj.remark}}</div>
        </div>
        <div class="flex-item-form">
          <div class="text">配送方式：</div>
          <div class="text">
            <el-radio-group v-model="expressWay" @change="onExpressWay">
              <el-radio :label="1">快递</el-radio>
              <!-- <el-radio :label="2">县域配送</el-radio>
              <el-radio :label="3">到店自提</el-radio> -->
            </el-radio-group>
          </div>
        </div>
        <div class="express-item flex-item-form flex" v-for="(item,index) in expressList">
          <div class="text">包裹{{index + 1}}:</div>
          <div class="flex-1 margin-left">
            <div class="express-item-option" v-if="expressWay === 1">
              <div class="margin-top-10 margin-bottom-10">
                <div class="inline-block">
                  <el-select v-model="item.expressCode" placeholder="请选择快递公司"  size="small">
                    <el-option
                      v-for="expressItem in expressCompanyArr"
                      :key="expressItem.code"
                      :label="expressItem.name"
                      :value="expressItem.code">
                    </el-option>
                  </el-select>
                </div>
                <div class="margin-left inline-block">
                  <el-input v-model="item.expressSn" placeholder="请填写物流单号" size="small"></el-input>
                </div>
                <div class="margin-left inline-block flex">
                  <el-button icon="el-icon-delete" size="small" type="danger" v-if="dataSource.length > 1 && expressList.length > 1 && item.goodsList.length === 0 " @click="deleteExpressNo(index)">不拆了</el-button>
                  <!-- <el-button icon="el-icon-plus" size="small"  type="primary" v-if="dataSource.length > 1 && expressList.length < orderItemObj.orderGoodsList.length && item.goodsList.length > 1 && index === expressList.length - 1 " @click="newClick" >拆单来发货</el-button> -->
                  <el-button icon="el-icon-plus" size="small"  type="primary" v-if="false && dataSource.length > 1 && selectedGoods.length < orderItemObj.orderGoodsList.length" @click="getOrderGoods(item)">选择发货商品</el-button>
                </div>
              </div>
            </div>
            <div class="express-item-goods">
              <div class="order-page-wrap margin-right">
                <div class="order-page-title">
                  <div class="flex-4">
                    <div class="flex">
                      <div class="flex-3">订单商品</div>
                      <div class="flex-1">单价/数量</div>
                      <div class="flex-1">发货数量</div>
                      <div class="flex-1">操作</div>
                    </div>
                  </div>
                </div>
                <div class="order-page-list">
                  <div class="order-item divide-list">
                    <div class="order-item-content">
                      <div class="flex-4 border-right">
                        <div class="order-item-goods flex" v-for="(goods,$index) in item.goodsList">
                          <div class="flex flex-3">
                            <el-image class="radius-6" style="width: 62px; height: 62px" :src="goods.cover"></el-image>
                            <div class="flex-1 padding-left-10 padding-right-30 flex flex-column">
                              <div class="goods-name flex-1">{{goods.goodsName}}</div>
                              <div class="gray">{{goods.skuName}}</div>
                            </div>
                          </div>
                          <div class="flex-1 flex flex-column">
                            <div class="goods-price flex-1">¥ {{goods.price}}</div>
                            <div class="gray"> x{{goods.goodsNum}}</div>
                          </div>
                          <div class="goods-refund flex-1 flex flex-column">
                            <div class="flex-1">{{goods.goodsNum - goods.refundNum}}</div>
                            <div :class="goods.refundStatus === 1 ? 'flex-1 red':'flex-1'" v-if="goods.refundStatus">{{goodsRefundStatus[goods.refundStatus]}}</div>
                          </div>
                          <div class="action flex-1">
                            <el-button class="function-button" size="mini"  type="danger" v-if="dataSource.length > 1 && expressList.length > 1" @click="removeGoods(index, goods,$index)">放下一个包裹</el-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
        <div class="margin-top">
          <el-button @click="cancelForm">取 消</el-button>
          <el-button type="primary" @click="submitSingleSend">确 定</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
  import {URL} from '../../../config'
  import {goodsRefundStatus, goodsRefundStatusArray, orderStatus, orderStatusArray} from '../../../assets/js/status.js'
  import {
    disposeSearchData,
    getNextDayTimestamp,
    isEmpty,
    isNormalLongId,
    parseTimeArray
  } from '../../../assets/js/utils'
  import {plusDownloadExcelTask} from '../../../store/excel'
  import {mapState} from 'vuex'

  function createNewExpressData(){
    return {
      expressCode:"",
      expressSn:"",
      goodsList:[],
    };
  }

  export default {
    name: 'supplierOrderWaitSendList',
    components:{
    },
    data(){
      return {
        loading: false,
        showMergeSendOrder: false,
        dialog: false,
        gridData: [],
        listData: [],
        expressCompanyArr:[],
        form: {
          expressCode:'',
          expressSn: '',
        },
        expressList:[],
        /* 数据表格 */
        orderStatus,
        orderStatusArray,
        goodsRefundStatus,
        goodsRefundStatusArray,
        searchKeyType:[
          {id: 'orderId', name: '订单编号'},
          {id: 'receiveName', name: '收件人姓名'},
          {id: 'receivePhone', name: '收件人电话'},
          {id: 'receiveAddressInfo', name: '收件人地址'},
        ],
        searchTimeType:[
          {id: '', name: '全部'},
          {id: 'createTime', name: '下单时间'},
          {id: 'payTime', name: '支付时间'},
        ],
        searchData:{
          searchType:'orderId',
          searchValue:'',
          status:2,
          timeType:'',
          selectTime:[],
        },
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          }
        },
        tableData: [], // 数据列表
        currentPage: 1, // 当前页码
        totalListNum: 0, // 列表总条量
        pageSize: 5, // 每页显示得条数
        componentSelectGoods:[],
        selectedGoods:[],
        selectedOrderId:"", // 已选子订单ID
        selectedOrderType:"", // 已选订单类型
        selectedParentOrderId:"", // 已选主订单ID
        visibleSingleSendDrawer:false,
        visibleSelectedOrderGoodsDialog:false,
        itemObj:{},// 当前选择发货的对象
        orderItemObj:{orderGoodsList:[]},
        multipleSelection:[],
        dataSource:[],
        expressWay:1,// 配送方式：1-快递 2-县域配送 3-到店自提
        userAssignDeliveryMethod: '邮寄快递', // 选择发货的这个订单指定的配送方式文本
        expressWaySelected:1, // 选择发货的这个订单指定的配送方式

        businessTypeList: ['零售商品', '包装', '代运营', '产品拍图', '土地认租', '农场提菜', '农场服务']
      }
    },
    created(){
      this.getList();
      this.getExpressCompany();
    },
    methods:{
      isNormalLongId,
      closed() {
        this.$emit('closed', ...arguments)
      },
      /**
       * 拼接搜索参数
       */
      jointSearchData(){
        let data = {
          pageNo:this.currentPage,
          pageSize:this.pageSize,
          orderTypeStr:'0, 1, 3, 5',
          businessType:1
        };
        data = Object.assign(data,this.searchData);
        disposeSearchData(data);
        let selectTime = data.selectTime;
        let startTime = 0;
        let endTime = 0;

        if(!isEmpty(selectTime)){
          let times = selectTime.map(res=>new Date(res).getTime())
          startTime = times[0];
          endTime = getNextDayTimestamp(times[1]);
        }

        data.startTime = startTime;
        data.endTime = endTime;
        delete data.selectTime;
        return data;
      },
      /**
       * 搜索
       */
      search(){
        this.currentPage = 1;
        this.getList()
      },
      showDetail(row){
        this.$refs['orderTable'].toggleRowExpansion(row);
      },
      /**
       * 查询列表数据
       */
      getList(){
        return new Promise((resolve, reject) => {
          let data = this.jointSearchData();
          this.loading = true;
          this.axios.get(URL.supplierOrder.waitSendOrderList,{params:data}).then(res=>{
            parseTimeArray(res.data.records,["createTime", "payTime"]);
            this.totalListNum = parseInt(res.data.total);
            this.tableData = res.data.records;
            console.info(res.data.records);
            resolve(res);
          }).catch(res=>{
            this.tableData = [];
            // reject(res);
          }).finally(res=>{
            this.loading = false;
          })
        })
      },
      getExpressCompany(){
        let name = "";
        return new Promise((resolve, reject) => {
          this.axios.get(URL.expressCompany.list,{name:name}).then(res=>{
            this.expressCompanyArr = res.data;
            resolve(res);
          }).catch(res=>{
            this.tableData = [];
          }).finally(res=>{
          })
        })
      },
      /**
       * pageSize 改变时会触发：选择每页显示条数
       */
      handleSizeChange(val,type){
        this.pageSize = val;
        this.currentPage = 1;
        this.getList()
      },
      /**
       * currentPage 改变时会触发：选择页数
       */
      handleCurrentChange(val,type){
        this.currentPage = val;
        this.getList();
      },
      rowStyle({ row, rowIndex}) {
        if (row.canSendNum === 0) {
          return 'color:red'
        } else {
          return ''
        }
      },
      /**
       * singleSend 单笔订单发货,可进行拆单发货 点击发货调用
       */
      singleSend(row){
        this.expressWay = row.expressWaySelected;
        this.initSendData(row);
        this.visibleSingleSendDrawer = true;
        this.loading = true;
        let data = {
          orderId:row.id
        };
        this.axios.get(URL.supplierOrder.getSameAddressOrder,{params:data}).then(res=>{
          if(res.code !== 0){
            this.$message({
              type: 'error',
              message: res.msg
            });
          }else{
            parseTimeArray(res.data,["createTime", "payTime"]);
            this.gridData = res.data.filter(res=>res.expressWaySelected == row.expressWaySelected);
            //初始化数据
            this.form.expressCode = "";
            this.form.expressSn = "";
          }
        }).catch(res=>{
        }).finally(()=>{
          this.loading = false;
        });
      },
      initSendData(row){
        this.selectedOrderId = row.id;
        this.selectedOrderType = row.orderType;
        this.selectedParentOrderId = row.parentOrderId;
        this.expressWaySelected = row.expressWaySelected;
        if (row.expressWaySelected == 1) {
          this.userAssignDeliveryMethod = '邮寄快递'
        } else if (row.expressWaySelected == 2) {
          this.userAssignDeliveryMethod = '县域配送'
        } else {
          this.userAssignDeliveryMethod = '到店自提'
        }
        this.expressList = [];
        let expressInfo = createNewExpressData();
        this.orderItemObj = row;
        let goodsList = row.orderGoodsList.filter(res=>res.goodsNum !== res.refundNum);
        //获取已发货的信息
        this.selectedGoods = goodsList.map(res=>res.id);
        expressInfo.goodsList = [].concat(goodsList);//初始化全选，拆单自己删除再重新选择
        this.dataSource = [].concat(goodsList);
        this.expressList.push(...[expressInfo]);
      },
      /**
       * batchSend 同一个用户，同一收货地址合并发货
       */
      batchSendView(){
        this.visibleSingleSendDrawer = false;
        this.showMergeSendOrder=true;
      },
      handleClose(done) {
        if (this.loading) {
          return;
        }
        setTimeout(() => {
          this.loading = false;
        }, 400);
      },
      submitBatchSendOrder(){
        // if(!this.multipleSelection.length) return this.$message.error('您没有选中要发货的订单');
        // let data = {
        //   orderIds:this.multipleSelection.map(res=>res.id),
        // };
        let data = {
          orderIds:this.gridData.map(res=>res.id),
        };
        data = Object.assign(data,this.form);
        data.expressWay = this.expressWay;
        this.loading = true;
        this.axios.post(URL.supplierOrder.submitBatchSendOrder,data).then(res=>{
          if(res.code !== 0){
            this.$message({
              type: 'error',
              message: res.msg
            });
          }else{
            this.showMergeSendOrder=false;
            this.getList()
          }

        }).catch(res=>{
        }).finally(()=>{
          this.loading = false;
        });
      },
      // 设置选择
      onExpressWay(e) {
        console.log('选择配送方式',e)
        if (e !== this.expressWaySelected) {
          this.$confirm(`买家已选【${this.userAssignDeliveryMethod}】，如切换配送方式，请提前与买家沟通确认，因此产生的损失将由商家自行承担，请谨慎操作`, '提示', {
            confirmButtonText: '切换',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.expressWay = e
          }).catch(() => {
            this.expressWay = this.expressWaySelected
          });
        }
      },

      cancelForm() {
        this.loading = false;
        this.showMergeSendOrder = false;
        this.visibleSingleSendDrawer = false;
      },

      getOrderGoods(item){
        this.componentSelectGoods = [];
        this.visibleSelectedOrderGoodsDialog = true;
        this.itemObj = item;
      },
      newClick() {
        let len = this.orderItemObj.orderGoodsList.length;
        let expressLen = this.expressList.length;
        if(expressLen < len){
          let data = createNewExpressData();
          this.expressList.push(...[data]);
          console.log("this.expressList",this.expressList);
        }else{
          this.$message.error("拆单发货次数已经达到上限");
          return false;
        }
      },
      deleteExpressNo(index) {
        let expressObj = this.expressList[index];
        if (expressObj) {
          if(expressObj.goodsList.length === 1){
            this.$message.error("只有一个发货单，不支持删除");
            return false;
          }
          if(expressObj.goodsList.length > 1){
            expressObj.goodsList.forEach(itemRes=>{
              if(this.selectedGoods.includes(itemRes.orderGoodsId)){
                let index = this.selectedGoods.indexOf(itemRes.orderGoodsId);
                this.selectedGoods.splice(index,1);
              }
            });
          }
          this.expressList.splice(index,1);

          if(this.expressList.length === 1){
            this.initSendData(this.orderItemObj);
          }

        }
      },
      removeGoods(index, goods, tableIndex){
        let item = this.expressList[index];
        let nextIndex = index + 1;
        if (nextIndex === this.expressList.length){
          nextIndex = 0;
        }
        item.goodsList = item.goodsList.filter((res,i)=>i !== tableIndex);
        let goodsId = goods.id;
        this.selectedGoods = this.selectedGoods.filter(res=>res !== goodsId);
        let nextItem = this.expressList[nextIndex];
        nextItem.goodsList.push(goods);

        if(item.goodsList.length===0){
          this.expressList.splice(index,1);
        }
      },
      submitSingleSend(){
        let expressList = this.expressList;
        let len = 0;
        let orderGoodsLen = this.orderItemObj.orderGoodsList.filter(res=>res.goodsNum !== res.refundNum).length;
        let flag = expressList.every(itemRes=>{
          let goodsLen = itemRes.goodsList.length;
          if(goodsLen === 0){
            this.$message.error("请选择发货的商品");
            return false;
          }
          len = len + itemRes.goodsList.length;
          if( this.expressWay === 1  && (isEmpty(itemRes.expressCode) || isEmpty(itemRes.expressSn)) ){
            this.$message.error("请完善快递信息");
            return false;
          }
          itemRes.goodsList.forEach(goodsItem=>{
            goodsItem.num = goodsItem.canSendNum;
            goodsItem.orderGoodsId = goodsItem.id;
          });
          return true;
        });

        if(!flag){
          return flag;
        }
        if(len < orderGoodsLen){
          this.$message.error("请选择完商品一次性操作发货")
          return false;
        }

        let data = {
          "orderId": this.selectedOrderId,
          "expressList": expressList,
          "expressWay":this.expressWay,
        };

        this.loading = true;
        this.axios.post(URL.supplierOrder.submitSingleSend, data).then(res=>{
          if(res.code !== 0){
            this.$message({
              type: 'error',
              message: res.msg
            });
          }else{
            this.visibleSingleSendDrawer=false;
            this.getList();
          }
        }).catch(res=>{
        }).finally(()=>{
          this.loading = false;
        })
      },
      /**
       * handleSelectionChange 当选择项发生变化时会触发该事件 table 表格选中
       */
      handleSelectionChange(val){
        this.multipleSelection = val;
      },
      /**
       * 导出excel
       */
      downloadExcel(){
        let searchData = this.jointSearchData();
        let data = {
          name:'供应商待发货订单报表',
          params:{
            excelType:1001,
            excelParameterJson:JSON.stringify(searchData),
          },
        };
        plusDownloadExcelTask.call(this,data);
      },
    },
    computed:{
      ...mapState({
        showPoint:state=>state.userInfo.showCostAndPurchase,
      }),
    },
    watch:{
      componentSelectGoods:function (newValue) {
        if(!isEmpty(newValue)){
          this.itemObj.goodsList.push(...newValue);
          newValue.forEach(itemRes=>{
            if(!this.selectedGoods.includes(itemRes.id)){
                this.selectedGoods.push(itemRes.id);
            }
          });
        }
      },
      expressWay(v,ov){
        if(v === 2 || v === 3){
          if(1 === ov){
            this.initSendData(this.orderItemObj);
          }
        }
      },
    },
  }
</script>

<style lang="less" scoped>
  @import "../../../assets/css/main";
  @import "../../../assets/css/order";
</style>
